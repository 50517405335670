.tab {
  @apply px-7 py-4 bg-white text-sm font-semibold text-neutral-600  rounded-t-lg cursor-pointer mr-1;
  span {
    padding: 2px 5.5px;
    @apply text-xs text-drop-purple-400 bg-drop-purple-100 rounded-full font-extralight;
  }
}

.tab-active {
  @apply text-white bg-drop-purple-400 relative;
  span {
    @apply text-drop-purple-400;
  }
  &::before {
    content: '';
    @apply absolute block w-5 h-5 bg-drop-purple-400 -bottom-1 rotate-45 rounded;
    left: calc(50% - 10px);
  }
}
