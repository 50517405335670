.navbar {
  @apply font-sans flex text-center justify-between py-6 px-14 bg-white items-center w-full shadow-md shadow-gray-200 z-40 fixed;
}

.menu-logo {
  @apply text-2xl no-underline text-gray-800 hover:text-blue-900;
}

.menu {
  @apply flex justify-between items-center gap-x-14;
}

.menu-item {
  @apply text-base no-underline font-semibold text-drop-purple-500 p-2 hover:text-blue-900;
}

.user-menu {
  @apply h-10 w-10 rounded-full text-drop-purple-500 bg-drop-purple-100 flex justify-center items-center text-xl relative;
}

.drop-down-item {
  @apply px-4 py-5 w-64 text-left text-base text-neutral-700 hover:text-drop-purple-500 border-b-1 border-gray-200;
}
