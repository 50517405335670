.step-nav {
  @apply flex w-full justify-between gap-x-4 p-6;
}

.btn-nav {
  @apply flex justify-center items-center gap-x-2 py-4 relative z-10;
}

.btn-disabled {
  @apply bg-black cursor-not-allowed #{!important};
}
