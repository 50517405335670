.un-save {
  @apply bg-drop-gray text-base font-bold rounded-2xl px-11 py-1;
}

.saving {
  @apply flex items-center gap-x-3;
}

.saving-icon {
  @apply w-8 h-8 p-1 text-2xl text-center bg-drop-gray text-drop-purple-500 inline-block rounded-full animate-spin-slow;
}

.saved {
  @apply flex items-center gap-x-3;
}

.saved-icon {
  @apply w-8 h-8 text-center text-xs bg-drop-green-100 text-drop-green-600 rounded-full flex items-center justify-center;
}
