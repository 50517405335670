.card-container {
  @apply flex flex-col rounded-lg shadow-lg overflow-hidden items-center m-2 flex-1 relative;
}

.image-container {
  @apply flex-shrink-0 mt-4;
}

.image {
  height: 64px;
  width: 64px;
}

.card-content {
  @apply flex-1 bg-white p-6 flex flex-col justify-between w-full;
}

.card-description {
  @apply mt-3 text-base text-gray-500 mb-4;
}

.card-disabled {
  @apply absolute w-full h-full bg-white opacity-40;
}
