.container {
  @apply fixed flex w-full justify-between bg-white;
}

.left-side {
  @apply w-1/6 border-r-2 border-drop-gray flex items-center px-4;
  max-width: 15rem;
}

.right-side {
  @apply w-5/6;
}

.back-save-link {
  @apply flex justify-center items-center gap-x-4 text-black visited:text-black no-underline;
}

.back-arrow {
  @apply h-10 w-10 border-2 border-drop-gray rounded-full flex justify-center items-center;
}

.stepper {
  @apply pt-24;
}

.fixed-sidebar {
  @apply bg-white fixed left-0 top-48 bottom-0 w-60  flex flex-col justify-between z-50;
}

.steps {
  @apply w-full border border-drop-gray;
}

.offer-container {
  @apply px-4 py-6 border-b border-drop-gray;
}

.offer-title {
  @apply font-bold text-lg;
}

.offer-type {
  @apply text-gray-600 text-sm;
}

.support-container {
  @apply px-4 pb-12;
}

.support-link {
  @apply flex gap-x-3 text-drop-purple-500 items-center text-sm font-inter visited:text-drop-purple-500 hover:text-drop-purple-500 #{!important};
}

.support-icon {
  font-size: 11px;
  @apply w-6 h-6 bg-drop-gray rounded-md flex items-center justify-center;
}

.step-content-panel {
  @apply flex flex-col overflow-y-scroll pl-64 w-full;
}
