.header-container {
  @apply w-full flex justify-between items-center container mx-auto;
}

.header-bg {
  @apply bg-white w-full py-6;
}

.header-title {
  @apply text-3xl font-bold;
}

.header-right-section {
  @apply flex justify-between items-center gap-x-4;
}

.create-button {
  @apply py-4 w-64 bg-gray-200 rounded-full text-base font-bold text-center;
  @apply text-neutral-700 no-underline #{!important};
}

.tabs-container {
  @apply flex justify-between items-center border-b border-gray-300;
}

.page-body {
  @apply py-10 container mx-auto;
}

.offers {
  @apply flex flex-col justify-center items-center py-6;
}
