.container {
  @apply px-4 py-6 flex items-center gap-x-3 transition-colors ease-in duration-300 border border-drop-gray;
}

.base-id {
  @apply w-8 h-8 text-sm font-semibold rounded-full flex items-center justify-center bg-drop-purple-50 text-drop-purple-500 transition-colors ease-in duration-300;
}

.id-active {
  @apply bg-drop-purple-500 text-white #{!important};
}
