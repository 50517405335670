.segmented-buttons-container {
  @apply flex text-center justify-center;
  div:first-child {
    @apply rounded-tl rounded-bl;
  }
  div:last-child {
    @apply rounded-tr rounded-br border-r-gray-700;
  }
}

.segmented-button {
  @apply py-2 px-4 text-sm relative text-black bg-white border border-y-gray-700 border-l-gray-700 min-w-25;
  input {
    @apply opacity-0 m-0 top-0 bottom-0 right-0 left-0 absolute w-full h-full cursor-pointer;
  }
}

.active-button {
  @apply bg-gray-200;
}
