.card-list {
  @apply bg-white rounded-lg w-full p-6 mb-4 border-l-8 border-drop-green-500;
}

.top-section {
  @apply flex w-full items-center justify-between pb-4;
}

.offer-name {
  @apply text-xl font-bold text-neutral-700;
}

.performance-box {
  @apply flex items-center gap-x-4;
}

.performance-btn {
  @apply bg-drop-purple-100 text-drop-purple-500 text-base font-bold px-6 py-2 no-underline rounded-full;
}

.bottom-section {
  @apply flex w-full items-center justify-between;
}
