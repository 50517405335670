.container {
  @apply relative flex items-start;
}

.input-container {
  @apply flex items-center h-5;
}

.chackbox {
  @apply focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded;
}

.label-container {
  @apply ml-3 text-sm;
}
