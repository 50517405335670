.create-container {
  @apply text-left container mx-auto px-4;
}

.header {
  @apply m-2;
}

.title {
  @apply text-3xl font-bold tracking-tight my-6;
}

.subtitle {
  @apply text-xl font-bold tracking-tight my-2;
}

.text {
  @apply mb-4 text-sm;
}

.type-title {
  @apply text-xl font-bold tracking-tight mt-10;
}

.types-container {
  @apply flex;
}
