.card-list {
  @apply bg-white rounded-lg w-full p-6 mb-4 border-l-8 border-drop-green-500;
}

.top-section {
  @apply flex w-full items-center justify-between pb-4;
}

.offer-name {
  @apply text-xl font-bold text-neutral-700;
}

.performance-box {
  @apply flex items-center gap-x-4;
}

.performance-btn {
  @apply bg-drop-purple-100 text-drop-purple-500 text-base font-bold px-6 py-2 no-underline rounded-full;
}

.options-btn {
  @apply bg-drop-gray w-10 h-10 flex items-center justify-center text-xl font-bold rounded-full relative;
}

.bottom-section {
  @apply flex w-full items-center justify-between;
}

.b-left-box {
  @apply flex items-center justify-between gap-x-4;
}

.offer-state-box {
  @apply flex items-center justify-between gap-x-2 bg-drop-green-50 px-3 py-2 rounded-full;
}

.state-circle {
  @apply bg-drop-green-500 w-2 h-2 rounded-full;
}

.b-right-box {
  @apply flex items-center gap-x-3;
}

.offer-state {
  @apply text-sm font-semibold capitalize;
}

.offer-type {
  @apply text-sm text-neutral-500 flex gap-x-2 items-center capitalize;
}

.offer-message {
  @apply text-sm font-semibold px-3 border-r border-neutral-300 text-neutral-500 hidden;
}

.offer-date {
  @apply text-sm text-neutral-600;
}

.menu-icon {
  @apply relative z-0;
}

// This is listed to be reviewed

.review-box {
  @apply border-drop-yellow-500 #{!important};
}

.draft-box {
  @apply border-drop-purple-500 #{!important};
}

.review-state {
  @apply bg-drop-yellow-100 #{!important};
}

.draft-state {
  @apply bg-drop-purple-100 #{!important};
}

.review-circle {
  @apply bg-drop-yellow-500 #{!important};
}

.draft-circle {
  @apply bg-drop-purple-500 #{!important};
}

.drop-down-item {
  @apply px-4 py-5 w-72 text-left text-base text-neutral-700 hover:text-drop-purple-500 border-b-1 border-gray-200 z-10;
}
