@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-gray-100;
}

body,
#root {
  height: 100vh;
}

@layer base {
  a {
    @apply underline underline-offset-2 text-drop-purple-500 hover:text-drop-purple-300 hover:underline-offset-4 transition;
  }

  h1 {
    @apply text-3xl font-bold tracking-tight text-slate-900
  }

  h2 {
    @apply text-2xl font-bold tracking-tight text-slate-900
  }

  h3 {
    @apply text-xl font-bold tracking-tight text-slate-900
  }

  h4 {
    @apply text-lg font-bold tracking-tight text-slate-900
  }

  h5 {
    @apply text-base font-bold tracking-tight text-slate-900
  }
}

@layer components {
  .btn {
    @apply rounded-full py-4 px-8 w-72 font-medium text-lg text-center tracking-wide transform transition no-underline;
  }

  .btn-primary {
    @apply bg-zinc-900 text-white hover:bg-gray-900 focus:ring-gray-900 focus:ring-opacity-50 focus:outline-none focus:ring focus:ring-offset-2 active:bg-gray-900 shadow-lg disabled:bg-zinc-700 hover:text-white;
  }

  .btn-ghost-black {
    @apply inline-flex gap-2 border-1 border-slate-700 py-2 px-4 rounded-full text-slate-700 font-medium items-center justify-center hover:text-slate-900 hover:bg-slate-800 hover:bg-opacity-5 hover:border-slate-900 focus:outline-none;
  }

  .btn-black {
    @apply inline-flex gap-2 bg-slate-900 py-2 px-4 rounded-full text-white font-medium items-center justify-center hover:bg-slate-800 hover:text-white focus:outline-none;
  }

  .btn-ghost-white {
    @apply inline-flex gap-2 border-1 border-white py-2 px-4 rounded-full text-white font-medium items-center justify-center hover:text-white hover:bg-white hover:bg-opacity-5 hover:border-white focus:outline-none;
  }

  .btn-white {
    @apply inline-flex gap-2 bg-white py-2 px-4 rounded-full text-zinc-900 font-medium items-center justify-center hover:bg-white hover:text-black focus:outline-none;
  }

  .form-group {
    @apply mb-4 pb-8 relative;
  }

  .form-control {
    @apply block w-full p-4 bg-gray-100 sm:text-sm rounded-md border-transparent group-invalid:border-red-300 group-invalid:text-red-900 group-invalid:placeholder-red-300 focus:outline-none group-invalid:focus:ring-red-500 group-invalid:focus:border-red-500 shadow-sm;
  }
}
