.form-label {
  @apply block text-gray-700 text-sm font-bold mb-2;
}

.input-text {
  @apply shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight;
  &:focus {
    @apply outline-none;
  }
}

.input-select {
  @apply appearance-none block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0;
  &:focus {
    @apply bg-white border-green-500 outline-none text-gray-700;
  }
}

.input-image {
  @apply block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0;
  &:focus {
    @apply text-gray-700 bg-white border-green-500 outline-none;
  }
}

.input-textarea {
  @apply shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight;
  &:focus {
    @apply outline-none;
  }
}

.submit {
  @apply bg-green-400 hover:bg-green-600 text-white font-bold mt-4 py-2 px-4 rounded;
  &:hover {
    @apply bg-green-600;
  }
  &:focus {
    @apply outline-none;
  }
}
